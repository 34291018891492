import React, { useState } from 'react';
import './App.css';

function App() {
  const [formData, setFormData] = useState({ 
    name: '', 
    email: '', 
    phone: '', 
    organization: '', 
    referral: '', 
    remarks: '' 
  });
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_MAIN_URL}/api/signup`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });
      

      if (response.ok) {
        setMessage('Your signup was successful. Thank you!');
        setFormData({ name: '', email: '', phone: '', organization: '', referral: '', remarks: '' });
      } else {
        setMessage('An error occurred during signup. Please email result@buildiful.com if you need further assistance.');
      }
    } catch (error) {
      setMessage('An error occurred during signup. Please email result@buildiful.com if you need further assistance.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="App">
      <header className="site-description">
        <h1>Where Endless Immersive Explorations Begin</h1>
        <p>Signups are currently by invite only.</p>
        <p>Join our wait list now to reserve your spot.</p>
      </header>

      {/* <h1>Sign Up</h1> */}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="phone"
          placeholder="Your Phone Number (optional)"
          value={formData.phone}
          onChange={handleChange}
        />
        <input
          type="text"
          name="organization"
          placeholder="Organization (optional)"
          value={formData.organization}
          onChange={handleChange}
        />
        <input
          type="text"
          name="referral"
          placeholder="How did you hear about us? (optional)"
          value={formData.referral}
          onChange={handleChange}
        />
        <textarea
          name="remarks"
          placeholder="Remarks (optional)"
          value={formData.remarks}
          onChange={handleChange}
        ></textarea>
        <button
          type="submit"
          disabled={isLoading}
        >
            {isLoading ? <div className="spinner"></div> : 'SIGN UP'}
        </button>
      </form>
      {message && <p>{message}</p>}

      <footer className="footer">
        <p>&copy; 2024 Result.is by Buildiful</p>
      </footer>
    </div>
  );
}

export default App;
